// import { Container, Details } from "@/shared/ui";
import { Link } from "react-router-dom";
// import DropdownIcon from "@/shared/assets/icons/arrow-dropdown.svg?react";
// import { PlacesMenu } from "./places-menu";
// import userIcon from "@/shared/assets/icons/user.svg";
import myGuideIcon from "@/shared/assets/icons/my-guide-new.svg";
import logo from "@/shared/assets/icons/elistory-logo.svg";
import { ArrowIcon } from "@/shared/ui/icon";
import { Container } from "@/shared/ui";
import { useHeader } from "../hooks/use-header";

interface IProps {
  Account: React.ReactNode;
}

export const Header: React.FC<IProps> = ({ Account }) => {
  const { isMyGuide } = useHeader();

  return (
    <header
      className={`hidden md:block absolute md:fixed z-50 ${
        isMyGuide ? "py-0" : "pt-8 pb-0"
      }  px-5 w-full`}
    >
      <Container className="px-0 md:px-10">
        <div className="navbar mx-auto justify-center md:justify-normal bg-base-100 rounded-3xl md:px-10 shadow-xl">
          <div className="navbar-start">
            <Link to={"/home#plates"}>
              <img src={logo} alt="" className="" />
            </Link>
          </div>
          <div className="navbar-center hidden lg:flex">
            <div className="dropdown">
              <div
                tabIndex={0}
                role="button"
                className="btn m-1 bg-transparent border-none shadow-none"
              >
                Чем заняться
                <ArrowIcon />
              </div>
              <ul
                tabIndex={0}
                className="dropdown-content menu bg-base-100 rounded-box z-[1] w-52 p-2 shadow"
              >
                {/* Пример с оборачиванием <li> ссылкой */}

                <Link to={"/excursions"}>
                  <li className="p-2 hover:bg-gray-100 rounded-lg">
                    Экскурсии
                  </li>
                </Link>
                <Link to={"/places/list/sport-i-razvlechenie"}>
                  <li className="p-2 hover:bg-gray-100 rounded-lg">
                    Спорт и развлечения
                  </li>
                </Link>
              </ul>
            </div>
            <div className="dropdown">
              <div
                tabIndex={1}
                role="button"
                className="btn m-1 bg-transparent border-none shadow-none"
              >
                Куда сходить
                <ArrowIcon />
              </div>
              <ul
                tabIndex={1}
                className="dropdown-content menu bg-base-100 rounded-box z-[1] w-52 p-2 shadow"
              >
                <Link to={"/places/list/dostoprimechatelnosti"}>
                  <li className="p-2 hover:bg-gray-100 rounded-lg">
                    Достопримечательности
                  </li>
                </Link>
                <Link to={"/places/list/gastronomiya"}>
                  <li className="p-2 hover:bg-gray-100 rounded-lg">
                    Рестораны и кафе
                  </li>
                </Link>
                <Link to={"/places/list/place-category-1"}>
                  <li className="p-2 hover:bg-gray-100 rounded-lg">Музеи</li>
                </Link>
                <Link to={"/places/list/place-category-1"}>
                  <li className="p-2 hover:bg-gray-100 rounded-lg">Театры</li>
                </Link>

                <Link to={"/places/list/tualety"}>
                  <li className="p-2 hover:bg-gray-100 rounded-lg">Туалеты</li>
                </Link>
                <Link to={"/places/list/suvenirnye"}>
                  <li className="p-2 hover:bg-gray-100 rounded-lg">
                    Магазины и аптеки
                  </li>
                </Link>
              </ul>
            </div>
            <ul>
              <Link
                to={"/articles"}
                className="btn bg-transparent border-none shadow-none"
              >
                <li>Новости</li>
              </Link>
            </ul>
            <ul>
              <Link
                to={"/hotels"}
                className="btn bg-transparent border-none shadow-none"
              >
                <li>Отели</li>
              </Link>
            </ul>
            <ul>
              <Link
                to={"/events"}
                className="btn bg-transparent border-none shadow-none"
              >
                <li>Афиша</li>
              </Link>
            </ul>
            <ul>
              <Link
                to={"/home#mobile-app"}
                className="btn bg-transparent border-none shadow-none"
              >
                <li>Приложение</li>
              </Link>
            </ul>
          </div>
          <div className="navbar-end hidden md:flex">
            <Link
              to={"/my-guide"}
              className="btn hidden md:flex bg-white border-none shadow-none"
            >
              <img src={myGuideIcon} alt="" />
              Мой гид
            </Link>
            <div className="btn bg-white border-none shadow-none">
              {/* <img src={userIcon} alt="" /> */}
              <p className="hidden md:block ">{Account}</p>
            </div>
          </div>
        </div>
      </Container>
    </header>
  );
};

{
  /* <header className="fixed z-40 navbar bg-base-300 lg:bg-base-200 hidden lg:block">
      <div className="flex justify-between w-full px-2 lg:hidden">
        <Link
          to={"/home#plates"}
          className="text-xl uppercase kalmyk-font text-primary"
        >
          ЭЛИСТА
        </Link>
        <div className="">{Account}</div>
      </div>
      <Container direction="row" className="hidden lg:flex">
        <div className="flex justify-start navbar-start">
          <Link
            to={"/home#plates"}
            className="text-xl uppercase kalmyk-font text-primary"
          >
            ЭЛИСТА
          </Link>
        </div>
        <nav className="text-black navbar-center">
          <ul className="px-1 uppercase menu menu-horizontal gap-1">
            <li>
              <Link to={"/articles"}>Новости</Link>
            </li>
            <li>
              <Link to={"/events"}>Афиша</Link>
            </li>
            <Details>
              <summary
                tabIndex={0}
                role="button"
                className="relative flex flex-col items-center justify-center w-full h-full gap-2 border-current cursor-pointer"
              >
                <span className="flex items-center px-2">
                  Места <DropdownIcon className="w-5" />
                </span>
              </summary>
              <PlacesMenu
                className={
                  "p-2 shadow menu dropdown-content z-[1] bg-base-200 rounded-box"
                }
              />
            </Details>
            <li>
              <Link to={"/places/list/oteli"}>Отели</Link>
            </li>
            {/* <li>
              <Link to={"/tours"}>Туры</Link>
            </li> /*}
            <li>
              <Link to={"/excursions"}>Экскурсии</Link>
            </li>

            <li>
              <Link to={"/my-guide"}>Мой гид</Link>
            </li>
          </ul>
        </nav>
        <div className="flex justify-end navbar-end">{Account}</div>
      </Container>
    </header> */
}
